<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí các câu hỏi khảo sát {{ name_survey }}
      </h1>
    </div>

    <!--  button create question-->
    <div
      class="mt-10 mb-2"
      style="
        max-width: 972px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      "
    >
      <!-- list question-->
      <div>
        <template v-for="(question, i) in questions">
          <div :key="i">
            <div class="d-flex">
              <span class="text-h6">{{ i + 1 }}. </span>
              <p class="text-h6 mb-0" v-html="question.title"></p>
              <span
                class="red--text text-h6 ml-1"
                v-if="question.is_required === 1"
                >*</span
              >
              <v-icon @click="editQuestion(i)" class="pb-3" right
                >mdi-file-document-edit-outline</v-icon
              >
              <v-icon @click="deleteQuestion(question.id)" class="pb-3" right
                >mdi-delete</v-icon
              >
            </div>

            <template v-if="question.type === question_types.SHORT_ANSWER">
              <div style="width: 100%; max-width: 220px">
                <v-text-field readonly outlined></v-text-field>
              </div>
            </template>

            <template v-if="question.type === question_types.SINGLE_CHOICE">
              <v-radio-group readonly class="mt-0 pt-0">
                <template v-for="(option, iOption) in question.option">
                  <v-radio
                    :key="iOption"
                    :label="option"
                    :value="option"
                  ></v-radio>
                </template>
              </v-radio-group>
            </template>

            <template v-if="question.type === question_types.STAR_RATING">
              <v-rating
                :length="question.scale"
                readonly
                background-color="yellow"
                large
              ></v-rating>
            </template>

            <template v-if="question.type === question_types.MULTIPLE_CHOICE">
              <template v-for="(option, iOption) in question.option">
                <div :key="iOption">
                  <v-checkbox
                    class="pt-0 mt-0"
                    dense
                    readonly
                    :label="option"
                  ></v-checkbox>
                </div>
              </template>
            </template>

            <template v-if="question.type === question_types.DATE">
              <date-picker
                class="mb-4"
                disabled
                type="datetime"
                value-type="format"
                placeholder="Date"
              ></date-picker>
            </template>
          </div>
        </template>
      </div>

      <div style="background-color: rgb(244, 245, 245)" class="pt-5 pb-5">
        <v-btn
          style="
            margin-right: auto;
            margin-left: auto;
            display: block;
            background-color: rgb(0, 191, 111);
          "
          x-small
          large
          @click="openDiaLogQuestion"
        >
          <v-icon color="white" small dark>mdi-plus</v-icon
          ><span class="text-subtitle-1" style="color: white">Tạo câu hỏi</span>
        </v-btn>
      </div>

      <!-- button save-->
      <!--      <div style="width: 100%" class="text-center pt-8">-->
      <!--        <div style="background-color: #008323; margin-right: auto; margin-left: auto; display: inline-block" class="pl-7 pr-7 pt-4 pb-4 cursor-pointer">-->
      <!--          <span class="text-subtitle-1 text-white">-->
      <!--            Save-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <!-- modal create question-->
    <Form
      :show_dialog="dialogQuestion"
      :type_form="type_form"
      @setDialog="(e) => (this.dialogQuestion = e)"
      @resetDataQuestion="getQuestionsBySurveyId"
    />
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import { mapState } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "QuestionItem",
  components: {
    Form: () => import("@/view/pages/survey2/Question/Form"),
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      name_survey: "",
      dialogQuestion: false,
      type_form: null,
      question_types: {
        SHORT_ANSWER: 1,
        SINGLE_CHOICE: 2,
        STAR_RATING: 3,
        MULTIPLE_CHOICE: 4,
        DATE: 5,
      },
    };
  },
  computed: {
    ...mapState({
      option_groups: (state) => state.surveyQuestionStore.option_groups,
      questions: (state) => state.surveyQuestionStore.questions,
      question: (state) => state.surveyQuestionStore.question,
    }),
    survey_id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getAllOptionGroup();
    this.getQuestionsBySurveyId();
  },
  methods: {
    openDiaLogQuestion() {
      this.type_form = "add";
      this.$store.commit(
        "surveyQuestionStore/setQuestionNumber",
        this.questions.length + 1
      );
      this.dialogQuestion = true;
    },
    async getAllOptionGroup() {
      let vm = this;
      try {
        let res = await ApiService.get("prep-app/survey/all-option-group");
        if (res.status === 200) {
          vm.$store.commit("surveyQuestionStore/setOptionGroup", res.data);
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    async getQuestionsBySurveyId() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/survey/" + this.survey_id + "/questions"
        );
        if (res.status === 200) {
          vm.name_survey = res.data.survey;
          vm.$store.commit(
            "surveyQuestionStore/setQuestions",
            vm.transformQuestion(res.data.data_question)
          );
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    transformQuestion(questions) {
      let vm = this;
      return questions.map(function (question) {
        return {
          id: question.id,
          title: question.title,
          type: question.type,
          is_required: question.is_required,
          option_group_id: question.option_group_id,
          allow_other_text: question.allow_other_text,
          scale: question.scale,
          option:
            question.type === 2 || question.type === 4
              ? vm.checkNullOptionAnswer(question.option_answers)
              : [],
        };
      });
    },
    checkNullOptionAnswer(option_answer) {
      if (option_answer.length <= 0) {
        return [];
      }
      return option_answer.map(function (option) {
        return option.answer_text;
      });
    },
    editQuestion(i) {
      this.$store.commit(
        "surveyQuestionStore/setQuestion",
        JSON.parse(JSON.stringify(this.questions[i]))
      );
      this.$store.commit("surveyQuestionStore/setQuestionNumber", i + 1);
      this.type_form = "update";
      this.dialogQuestion = true;
    },
    deleteQuestion(question_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/survey/" + this.survey_id + "/questions/" + question_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getQuestionsBySurveyId();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
